import React from "react";
import Form from "./components/form";
import styles from "./Contact.module.scss";

const Contact = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.title}>CONTACT US</div>
        <div className={styles.form}>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Contact;
