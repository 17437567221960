import React from "react";
import Contact from "../scenes/contact";
import SEO from "../components/seo";

export default () => {
  return (
    <>
      <SEO title={"Contact"} />
      <Contact />
    </>
  );
};
