import React, { useState } from "react";
import TextField from "@components/inputField";
import styles from "./Form.module.scss";

const defaultState = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const Form = () => {
  const [state, setState] = useState(defaultState);
  const handleChange = e => {
    const {
      currentTarget: { name, value },
    } = e;
    setState(state => ({ ...state, [name]: value }));
  };
  return (
    <form
      method="post"
      name="contacts"
      netlify-honeypot="bot-field"
      data-netlify="true"
      className={styles.form}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contacts" />
      <div className={styles.row}>
        <div className={styles.column}>
          <TextField
            required
            id="name"
            name="name"
            placeholder="name"
            value={state.name}
            handleChange={handleChange}
          />
        </div>
        <div className={styles.column}>
          <TextField
            required
            id="email"
            name="email"
            type="email"
            placeholder="email"
            value={state.email}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <TextField
            required
            id="phone"
            name="phone"
            placeholder="phone"
            value={state.phone}
            handleChange={handleChange}
          />
        </div>
        <div className={styles.column}>
          <TextField
            id="subject"
            name="subject"
            placeholder="subject"
            value={state.subject}
            handleChange={handleChange}
          />
        </div>
      </div>

      <textarea
        className={styles.textarea}
        name="message"
        placeholder="Company Name and Message"
        id="message"
        value={state.message}
        onChange={handleChange}
      />
      <div className={styles.buttonBox}>
        <button className={styles.button} type="submit">
          submit
        </button>
      </div>
    </form>
  );
};

export default Form;
