import React from "react";
import { string, func, bool } from "prop-types";
import styles from "./InputField.module.scss";

const InputField = ({
  id,
  value,
  required,
  name,
  placeholder,
  label,
  handleChange,
  type,
  inputStyle,
  fieldStyle,
}) => {
  return (
    <div className={fieldStyle || styles.inputField}>
      <label htmlFor={id} className={styles.label}>
        {label}
        <input
          required={required}
          className={inputStyle || styles.input}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

InputField.propTypes = {
  id: string,
  type: string,
  name: string,
  value: string,
  placeholder: string,
  label: string,
  required: bool,
  handleSetValue: func,
};

InputField.defaultProps = {
  type: "text",
  required: false,
};

export default InputField;
